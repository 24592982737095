import React from 'react';
import NavBar from '../components/NavBar';
import PageSectionHeader from '../components/PageSectionHeader';
import { FrontendSkills, BackendSkills, DatabaseSkills, CloudSkills, ToolsSkills, SoftSkills } from '../data/skills.ts';
import Skill from '../components/Skill';
import ImgSelfie from '../images/selfie.jpg';
import ImgPurdueP from '../images/purdue_p.png';
import './Page.css';
import './About.css';

const About = () => {
    return (
        <div>
            <NavBar />
            <div className="page-container">
                <div className="page-header">
                    <div className="page-header-text extra-bold-text">
                        About
                    </div>
                    <div className="page-header-underline page-header-underline-about" />
                </div>

                <div className="page-content regular-text">
                    <div className="page-section">
                        <img src={ImgSelfie} alt="Jack Furmanek" className="image-shadow-about image-right" style={{ width: '200px', height: '200px' }} />
                        <p>
                            Hello there! I'm Jack Furmanek, a Computer Science student in the Midwest.
                        </p>
                        <p>
                            I have a drive to solve problems and design features that enrich the lives of those around me. On this site, you'll find a lot of (mostly) professional information about me, my hobbies, experience, and goals.
                        </p>
                        <p>
                            You can paginate through the site by using the three buttons up there in the header. There's also a few hotlinks to my resume and LinkedIn.
                        </p>
                    </div>
                    
                    <div className="page-section">
                        <PageSectionHeader name="Education" section="about" />
                        <p>
                            I was born and raised in Chesterton, Indiana, about five miles from the coast of Lake Michigan, and an hour out from Chicago. I graduated high school in 2021.
                        </p>
                        <img src={ImgPurdueP} alt="Purdue University" className="image-shadow-about image-right" style={{ width: '250px', height: '156.25px' }} />
                        <p>
                        I'm currently studying Computer Science at Purdue University in West Lafayette, Indiana. In my studies, I'm focusing on a concentration in Software Engineering, as well as a minor in Mathematics.
                        </p>
                        <p>
                            My coursework has covered many areas surrounding programming, including OOP, frontend, backend, and database development. I've also taken courses in discrete mathematics, calculus, and linear algebra.
                        </p>
                    </div>

                    <div className="page-section">
                        <PageSectionHeader name="Skills" section="about" />
                        <p>
                            I have experience with a variety of programming languages and tools, including:
                        </p>
                        
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Frontend:</div>
                            {Object.values(FrontendSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='frontend' />
                            ))}
                        </div>
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Backend:</div>
                            {Object.values(BackendSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='backend' />
                            ))}
                        </div>
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Database:</div>
                            {Object.values(DatabaseSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='database' />
                            ))}
                        </div>
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Cloud:</div>
                            {Object.values(CloudSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='cloud' />
                            ))}
                        </div>
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Tools and IDEs:</div>
                            {Object.values(ToolsSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='tools' />
                            ))}
                        </div>
                        <div className="page-skills">
                            <div className="page-skills-area bold-text">Soft Skills:</div>
                            {Object.values(SoftSkills).map((skill) => (
                                <Skill skillname={skill} skillarea='soft' />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;