import React from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/Button';
import ArticleIcon from '@mui/icons-material/Article';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';

function NavBar() {

    return (
        <div>
            <div className="navbar-container bold-text">
                <div className="home-section">
                    <Link to="/" class="link-text regular-text" onClick={() => window.scrollTo(0, 0)}>
                        jackfurmanek.com
                    </Link>
                </div>
                <div className="sections-section">
                    <Link to="/about" className="section about-section" onClick={() => window.scrollTo(0, 0)}>
                        <div className="rounded-section rounded-about">
                            <div className="section-link">
                                <div className="section-link-text">
                                    About
                                </div>
                                <div className="section-link-underline"></div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/experience" className="section experience-section" onClick={() => window.scrollTo(0, 0)}>
                        <div className="rounded-section rounded-experience">
                            <div className="section-link">
                                Experience
                                <div className="section-link-underline"></div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/contact" className="section contact-section" onClick={() => window.scrollTo(0, 0)}>
                        <div className="rounded-section rounded-contact">
                            <div className="section-link">
                                Contact
                                <div className="section-link-underline"></div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="links-section medium-text">
                    <Tooltip title="Resume">
                        <IconButton style={{ color: '#888888' }} aria-label="resume" href={require('../data/resume.pdf')} target="_blank" rel="noopener noreferrer">
                            <ArticleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="LinkedIn">
                        <IconButton style={{ color: '#888888' }} aria-label="linkedin" href="https://linkedin.com/in/jackfurmanek/" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default NavBar;