import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
    return (
        <div>
            <div className="home-container extra-bold-text">
                <Link to="/about" className="section about-section">
                    <div className="section-link">
                        About
                        <div className="section-link-underline"></div>
                    </div>
                </Link>
                <Link to="/experience" className="section experience-section">
                    <div className="section-link">
                        Experience
                        <div className="section-link-underline"></div>
                    </div>
                </Link>
                <Link to="/contact" className="section contact-section">
                    <div className="section-link">
                        Contact
                        <div className="section-link-underline"></div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Home;