import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skill from './Skill';
import './WorkCard.css';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

export default function WorkCard({ name, company, date, location, blurb, description, image, skillnames, skillareas }) {
    if (skillnames.length !== skillareas.length) {
        throw new Error('skillnames and skillareas must have the same length');
    }

    console.log(description.split("/n"));

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className="work-card">
            <CardContent>
                <div className="work-card-header">
                    <div className="work-card-header-name bold-text">
                        {name}
                    </div>
                    <div className="work-card-header-date light-text">
                        {location}
                    </div>
                </div>
                <div className="work-card-header">
                    <div className="work-card-header-name light-text">
                        {company}
                    </div>
                    <div className="work-card-header-date light-text">
                        {date}
                    </div>
                </div>
            </CardContent>
            <CardMedia
                component="img"
                height="194"
                image={image}
                alt="image"
            />
            <CardContent className="work-card-blurb light-text">
                {blurb}
            </CardContent>
            <CardActions className="work-card-actions">
                <div className="work-card-actions-skills">
                    {skillnames.map((skillname, index) => (
                        <Skill
                            skillname={skillname}
                            skillarea={skillareas[index]}
                        />
                    ))}
                </div>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    className="work-card-actions-expand"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="work-card-description">
                    {description.split("\\n").map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                </CardContent>
            </Collapse>
        </Card>
    );
}
