import React from 'react';
import NavBar from '../components/NavBar';
import ProjectCard from '../components/ProjectCard.js';
import WorkCard from '../components/WorkCard.js';
import PageSectionHeader from '../components/PageSectionHeader';
import Hyperlink from '../components/Hyperlink';
import ImgExperienceWalsh from '../images/experience_walsh.png';
import ImgExperienceBe from '../images/experience_be.png';
import ImgAz900 from '../images/az_900.png';
import './Page.css';
import './Experience.css';
import { FrontendSkills, BackendSkills, DatabaseSkills, CloudSkills, ToolsSkills, SoftSkills } from '../data/skills.ts';

const Experience = () => {
    return (
        <div>
            <NavBar />
            <div className="page-container">
                <div className="page-header">
                    <div className="page-header-text extra-bold-text">
                        Experience
                    </div>
                    <div className="page-header-underline page-header-underline-experience" />
                </div>

                <div className="page-content regular-text">
                    <div className="page-section">
                        <PageSectionHeader name="Work Experience" section="experience" />
                        <p>
                            I have been fortunate to be a part of two key organizations that have helped me develop both my hard and soft skills in professional settings.
                        </p>
                        <div className="page-work">
                            <div>
                                <WorkCard
                                    name="Software Engineer Intern"
                                    company="The Walsh Group"
                                    date="May - August 2024"
                                    location="Chicago, IL"
                                    blurb="A construction company that specializes in large-scale projects across the United States"
                                    description="At The Walsh Group, I was immersed in the development team's full tech stack to learn how to build and maintain internal forms used throughout the company.
                                                \n In the frontend, I worked with TypeScript, jQuery, and the Telerik and Semantic UI libraries to create and style pages. In the backend, I used C# and .NET to create new endpoints and SQL Server to store data.
                                                \n This experience has helped me develop my skills in full-stack development, learning how every layer of a project interacts with each other, and how to work in a team environment."
                                    image={ImgExperienceWalsh}
                                    skillnames={[
                                        FrontendSkills.TypeScript,
                                        FrontendSkills.Telerik,
                                        FrontendSkills.Semantic,
                                        FrontendSkills.jQuery,
                                        FrontendSkills.Ajax,
                                        BackendSkills.Csharp,
                                        BackendSkills.DotNet,
                                        DatabaseSkills.SQL,
                                        DatabaseSkills.SQLServer,
                                        ToolsSkills.Git,
                                        ToolsSkills.Rider,
                                        ToolsSkills.VisualStudio,
                                        SoftSkills.Teamwork,
                                    ]}
                                    skillareas={[
                                        "frontend",
                                        "frontend",
                                        "frontend",
                                        "frontend",
                                        "frontend",
                                        "backend",
                                        "backend",
                                        "database",
                                        "database",
                                        "tools",
                                        "tools",
                                        "tools",
                                        "soft",
                                    ]}
                                />
                            </div>
                            <div>
                                <WorkCard
                                    name="Content Team Lead"
                                    company="Boilerexams"
                                    date="April 2023 - Present"
                                    location="West Lafayette, IN"
                                    blurb="A site dedicated to giving Purdue students the ability to prepare for exams with explanation videos and an intuitive UX"
                                    description="Boilerexams is a student-run website that integrates previously-given Purdue exams with an intuitive user interface and pre-recorded explanations.
                                                \n As the Content Team Lead, I lead meetings among our explanation creators, maintain conversations with our development and relations teams, and oversee new additions to the project.
                                                \n My additional responsibilities include recording, uploading, and maintaining the website's 'Data Structures and Algorithms' and 'Calculus I' material."
                                    image={ImgExperienceBe}
                                    skillnames={[
                                        SoftSkills.Leadership,
                                        SoftSkills.ProblemSolving,
                                        SoftSkills.Teamwork,
                                    ]}
                                    skillareas={[
                                        "soft",
                                        "soft",
                                        "soft",
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="page-section">
                        <PageSectionHeader name="Projects" section="experience" />
                        <p>
                            In addition to my experiences, I've worked on a variety of projects that have helped me develop my skills and knowledge with different languages, frameworks, and tech stacks.
                        </p>
                        <div className="page-projects">
                            <div>
                                <ProjectCard
                                    name="Portfolio Website"
                                    date="August 2024"
                                    blurb="A personal website highlighting professional information about myself"
                                    description="Hey, you're viewing this right now!
                                                \n The focus of this website was building a quality, dynamic user interface. It's inteded to be a nice 'frontend wrapper' for what you'd find on my resume or CV. Thus, I spent time making sure it's adequately usable on both desktop and mobile devices.
                                                \n I used React for its reusable components to expedite some aspects of the site, such as the skills and the navigation bar. I also used the Material UI library of components for some areas, such as the cards here on the Experience page. The website is hosted on Azure as a Static Web App."
                                    skillnames={[
                                        FrontendSkills.React,
                                        FrontendSkills.MaterialUI,
                                        FrontendSkills.TypeScript,
                                        CloudSkills.Azure
                                    ]}
                                    skillareas={[
                                        "frontend",
                                        "frontend",
                                        "frontend",
                                        "cloud"
                                    ]}
                                />
                            </div>
                            <div>
                                <ProjectCard
                                    name="Sever"
                                    date="April 2024"
                                    blurb="A web application for tracking recurring purchases that you've quit"
                                    description="Given as a project during Purdue's CS 348 course, this application shows my experience with relational databases.
                                                \n I used Flask in Python for the backend, which communicated with a local Postgres database for storing user data."
                                    skillnames={[
                                        BackendSkills.Python,
                                        DatabaseSkills.SQL,
                                        DatabaseSkills.Postgres
                                    ]}
                                    skillareas={[
                                        "backend",
                                        "database",
                                        "database"
                                    ]}
                                />
                            </div>
                            <div>
                                <ProjectCard
                                    name="GameQuill"
                                    date="August - December 2023"
                                    blurb="A social media website where users can review video games and share them with others"
                                    description="GameQuill was a group project created with three others in an agile environment, involving user stories, sprints, and reviews.
                                                \n I generally worked across the full stack, while also taking time to focus on the graphical layout of the pages.
                                                \n To build it, we used a React frontend and a light Node.js backend. Hosting was done with Google Cloud's Firebase backend-as-a-service platform, as well as the IGDB.com API for game information."
                                    skillnames={[
                                        FrontendSkills.React,
                                        BackendSkills.Node,
                                        DatabaseSkills.NoSQL,
                                        CloudSkills.GoogleCloud,
                                        ToolsSkills.Postman,
                                        ToolsSkills.VSCode,
                                        SoftSkills.Teamwork,
                                    ]}
                                    skillareas={[
                                        "frontend",
                                        "backend",
                                        "database",
                                        "cloud",
                                        "tools",
                                        "tools",
                                        "soft"
                                    ]}
                                />
                            </div>
                            <div>
                                <ProjectCard
                                    name="MyShell"
                                    date="March - April 2023"
                                    blurb="A shell interpreter with a command-line user interface"
                                    description="MyShell was a project for Purdue's CS 252 course, where I learned about the inner workings of core Linux components, such as how malloc() and shell interfaces work."
                                    skillnames={[
                                        BackendSkills.C,
                                        BackendSkills.Cpp
                                    ]}
                                    skillareas={[
                                        "backend",
                                        "backend"
                                    ]}
                                />
                            </div>
                            <div>
                                <ProjectCard
                                    name="Discussion Board"
                                    date="October - December 2021"
                                    blurb="A discussion board where teachers and users can interact"
                                    description="We all start somewhere!
                                                \n This is my first project, given as an assignment in Purdue's CS course, CS 180. It was a simple Java project that helped me learn the basics of object-oriented programming.
                                                \n It was developed as a group project with four others."
                                    skillnames={[
                                        BackendSkills.Java,
                                        ToolsSkills.IntelliJ,
                                        SoftSkills.Teamwork
                                    ]}
                                    skillareas={[
                                        "backend",
                                        "tools",
                                        "soft"
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="page-section">
                        <PageSectionHeader name="Certification" section={"experience"} />
                        <img src={ImgAz900} alt="Microsoft Certified: Azure Fundamentals Certification" className="image-shadow-experience image-right" style={{ width: '150px', height: '150px' }} />
                        <p>
                            I have also earned a certification in 'Microsoft Certified: Azure Fundamentals' in August 2024. This certification displays knowledge of Azure services, security, compliance, trust, and management; as well as general cloud concepts and principles.
                        </p>
                        <p>
                            <Hyperlink linktext="View Certification" linkhref="https://learn.microsoft.com/en-us/users/jackfurmanek/transcript/d8y9xhxk4llx6gn" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;