import React from 'react';
import './PageSectionHeader.css';

const PageSectionHeader = ({ name, section }) => {
    return (
        <div>
            <div className="page-section-header bold-text" style={{ marginBottom: '15px' }} >
                <div className="page-section-header-text">
                    {name}
                </div>
                {section === 'about' && (
                    <div className="page-section-header-underline page-section-header-underline-about" />
                )}
                {section === 'experience' && (
                    <div className="page-section-header-underline page-section-header-underline-experience" />
                )}
                {section === 'contact' && (
                    <div className="page-section-header-underline page-section-header-underline-contact" />
                )}
            </div>
        </div>
    );
};

export default PageSectionHeader;