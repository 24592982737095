import React from 'react';
import '../data/skills.ts';
import './Skill.css';

const Skill = ({ skillname, skillarea }) => {
    return (
        <div>
            {skillarea === 'frontend' && (
                <div className="skill-container skill-container-frontend">
                    {skillname}
                </div>
            )}
            {skillarea === 'backend' && (
                <div className="skill-container skill-container-backend">
                    {skillname}
                </div>
            )}
            {skillarea === 'database' && (
                <div className="skill-container skill-container-database">
                    {skillname}
                </div>
            )}
            {skillarea === 'cloud' && (
                <div className="skill-container skill-container-cloud">
                    {skillname}
                </div>
            )}
            {skillarea === 'tools' && (
                <div className="skill-container skill-container-tools">
                    {skillname}
                </div>
            )}
            {skillarea === 'soft' && (
                <div className="skill-container skill-container-soft">
                    {skillname}
                </div>
            )}
        </div>
    );
};

export default Skill;