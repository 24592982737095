export enum SkillAreas {
    Frontend = 'Frontend',
    Backend = 'Backend',
    Database = 'Database',
    Cloud = 'Cloud',
    Tools = 'Tools',
}

export enum FrontendSkills {
    Ajax = 'Ajax',
    jQuery = 'jQuery',
    MaterialUI = 'Material UI',
    React = 'React',
    Semantic = 'Semantic UI',
    Telerik = 'Telerik UI',
    TypeScript = 'TypeScript',
}

export enum BackendSkills {
    DotNet = '.NET',
    Csharp = 'C#',
    C = 'C',
    Cpp = 'C++',
    Java = 'Java',
    Node = 'Node.js',
    Python = 'Python',
}

export enum DatabaseSkills {
    NoSQL = 'NoSQL',
    Postgres = 'PostgreSQL',
    SQL = 'SQL',
    SQLServer = 'SQL Server',
}

export enum CloudSkills {
    Azure = 'Azure',
    GoogleCloud = 'Google Cloud',
}

export enum ToolsSkills {
    Git = 'Git',
    IntelliJ = 'IntelliJ IDEA',
    Postman = 'Postman',
    Rider = 'Rider',
    VisualStudio = 'Visual Studio',
    VSCode = 'Visual Studio Code',
}

export enum SoftSkills {
    Leadership = 'Leadership',
    ProblemSolving = 'Problem Solving',
    Teamwork = 'Teamwork',
}