import React from 'react';
import Link from '@mui/material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Hyperlink.css';

const Hyperlink = ({ linktext, linkhref }) => {
    return (
        <div>
            <div className="hyperlink-container">
                <Link href={linkhref} target="_blank" rel="noopener noreferrer" underline="hover">
                    <div className="hyperlink-text">
                        <div>{linktext}</div>
                        <ArrowForwardIcon />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Hyperlink;