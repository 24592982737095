import React from 'react';
import NavBar from '../components/NavBar';
// import PageSectionHeader from '../components/PageSectionHeader';
import Hyperlink from '../components/Hyperlink';
import './Page.css';
import './Contact.css';

const Contact = () => {
    return (
        <div>
            <NavBar />
            <div className="page-container">
                <div className="page-header">
                    <div className="page-header-text extra-bold-text">
                        Contact
                    </div>
                    <div className="page-header-underline page-header-underline-contact" />
                </div>

                <div className="page-content page-content-contact regular-text">

                <Hyperlink linktext="jackfurmanek@gmail.com" linkhref="mailto:jackfurmanek@gmail.com" />
                <Hyperlink linktext="furmanek@purdue.edu" linkhref="mailto:furmanek@purdue.edu" />
                <Hyperlink linktext="LinkedIn" linkhref="https://www.linkedin.com/in/jackfurmanek/" />
                <Hyperlink linktext="Resume" linkhref={require('../data/resume.pdf')} target="_blank" rel="noopener noreferrer" />



                    {/* <div class="page-section page-section-contact">
                        <PageSectionHeader name="Email" section="contact" />
                        <p>
                            I have two email addresses you can best reach me at.
                        </p>
                        <ol>
                            <li className="page-list-item">
                                <div>Personal email: </div>
                                <Hyperlink linktext="jackfurmanek@gmail.com" linkhref="mailto:jackfurmanek@gmail.com" />
                            </li>
                            <li className="page-list-item">
                                <div>Purdue email: </div>
                                <Hyperlink linktext="furmanek@purdue.edu" linkhref="mailto:furmanek@purdue.edu" />
                            </li>
                        </ol>
                    </div>
                    <div class="page-section">
                        <PageSectionHeader name="LinkedIn" section="contact" />
                        <p>
                            <Hyperlink linktext="LinkedIn" linkhref="https:/linkedin.com/in/jackfurmanek" />
                        </p>
                        <p>
                            <Hyperlink linktext="Resume" linkhref={require('../data/resume.pdf')} target="_blank" rel="noopener noreferrer" />
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Contact;